import Vue from 'vue';
import VueRouter from 'vue-router';
import { mutations, actions } from '../store/store';
import axios from 'axios';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Auth',
		meta: { id: null, title: 'Авторизация' },
		component: () => import('../views/Auth/index.vue')
	},
	{
		path: '/password-change',
		name: 'PasswordChange',
		meta: { id: null, title: 'Смена пароля' },
		component: () => import('../views/Auth/index.vue')
	},
	{
		path: '/osmember',
		name: 'OsMember',
		component: () => import('../views/OsMember/index.vue'),
		children: [
			{
				path: 'activities',
				name: 'Activities',
				meta: { id: null, title: 'Активность членов ОС по НОК' },
				component: () => import('../views/OsMember/Activities/index.vue')
			},
			{
				path: 'form/:anketaResultId/:order',
				name: 'Form',
				meta: { id: null, title: 'Анкета НОК' },
				component: () => import('../views/OsMember/Form/index.vue')
			},
			{
				path: 'infoMO',
				name: 'InfoMO',
				meta: { id: null, title: 'Информация по медицинским организациям' },
				component: () => import('../views/OsMember/InfoMO/index.vue'),
				children: [
					{
						path: 'mo',
						meta: { id: null, title: 'Информация по МО' },
						component: () => import('../views/OsMember/InfoMO/ui/MobViewMO.vue')
					},
					{
						path: 'employee',
						meta: { id: null, title: 'Информация по МО' },
						component: () => import('../views/OsMember/InfoMO/ui/MobViewEmployee.vue')
					}
				]
			},
			{
				path: 'controlEvent',
				name: 'ControlEvent',
				meta: { id: null, title: 'Контрольные мероприятия' },
				component: () => import('../views/OsMember/ControlEvent/index.vue'),
				children: [
					{
						path: 'info',
						meta: { id: null, title: 'Контрольные мероприятия' },
						component: () => import('../views/OsMember/ControlEvent/ui/Info.vue')
					}
				]
			},
			{
				path: 'assignMO',
				name: 'AssignMO',
				meta: { id: null, title: 'Назначение медицинских организаций' },
				component: () => import('../views/OsMember/AssignMO/index.vue'),
				children: [
					{
						path: 'setMo',
						meta: { id: null, title: 'Назначение медицинских организаций' },
						component: () => import('../views/OsMember/AssignMO/ui/SetMo.vue')
					}
				]
			},
			{
				path: 'viewInfo',
				name: 'viewInfo',
				meta: { id: null, title: 'Просмотр информации' },
				component: () => import('../views/OsMember/Activities/viewInfo.vue')
			},
			{
				path: 'archive',
				name: 'archive',
				meta: { id: null, title: 'Архив' },
				component: () => import('../views/OsMember/Archive/index.vue')
			}
		]
	},
	{
		path: '/minzdrav',
		name: 'Minzdrav',
		component: () => import('../views/Minzdrav/index.vue'),
		children: [
			{
				path: 'general',
				name: 'General',
				meta: { id: '213c7ff8-6b63-449d-9e4e-adc3fc9e5fe0', title: 'Субьекты РФ' },
				component: () => import('../views/Minzdrav/General/index.vue')
			},
			{
				path: '/minzdrav/general/:anketaResultId',
				name: 'Anket',
				meta: { id: null, title: 'Не публичная анкета' },
				component: () => import('../views/Form/index.vue')
			},
			{
				path: 'fedmedorg',
				name: 'FedMedOrg',
				meta: { id: null, title: 'Федеральные МО' },
				component: () => import('../views/Minzdrav/FedMedOrg/index.vue')
			},
			{
				path: 'medical-organizations',
				name: 'MedicalOrganizationsMinzdrav',
				meta: { id: '213c7ff8-6b63-449d-9e4e-adc3fc9e5fe7', title: 'Медицинские организации' },
				component: () => import('../views/Minzdrav/MedicalOrganizations/index.vue')
			},
			{
				path: 'guide',
				name: 'Guide',
				meta: { id: '213c7ff8-6b63-449d-9e4e-adc3fc9e5fe9', title: 'Справочники Системы' },
				component: () => import('../views/Minzdrav/Guide/index.vue')
			},
			{
				path: 'noc-results-current-years',
				name: 'nocResultsCurrentYears',
				meta: { id: '213c7ff8-6b63-449d-9e4e-adc3fc9e5fe2', title: 'Результаты НОК текущего года' },
				component: () => import('../views/Minzdrav/NOCCurrentYear/index.vue')
			},
			{
				path: 'noc-results-archive',
				name: 'nocResultsArchive',
				meta: { id: '213c7ff8-6b63-449d-9e4e-adc3fc9e5fe4', title: 'Архив результатов НОК' },
				component: () => import('../views/Minzdrav/NOCArchive/index.vue')
			},
			{
				path: 'report-constructor',
				name: 'reportConstructor',
				meta: { id: '213c7ff8-6b63-449d-9e4e-adc3fc9e5fea', title: 'Универсальный отчет по МО' },
				component: () => import('../views/Minzdrav/ReportConstructor/index.vue')
			},
			{
				path: 'dinamical-filling',
				name: 'dinamicalFilling',
				meta: { id: null, title: 'Динамика заполнения анкет' },
				component: () => import('../views/Minzdrav/DinamicalFilling/index.vue')
			},
			{
				path: '/minzdrav/form-constructor',
				name: 'formConstructor',
				meta: { id: null, title: 'Конструктор анкет' },
				component: () => import('../views/Minzdrav/FormConstructor/index.vue'),
				children: [
					{
						path: '/minzdrav/form-constructor',
						name: 'formResult',
						meta: { id: null, title: 'Конструктор анкет' },
						component: () => import('../views/Minzdrav/FormConstructor/ui/TableForms.vue')
					},
					{
						path: 'add-form/:anketId',
						name: 'addForm',
						meta: { id: null, title: 'Добавить анкету' },
						component: () => import('../views/Minzdrav/FormConstructor/ui/AddForm.vue')
					},
					{
						path: 'edite-form/:anketId',
						name: 'editeForm',
						meta: { id: null, title: 'Редактировать анкету' },
						component: () => import('../views/Minzdrav/FormConstructor/ui/AddForm.vue')
					},
					{
						path: 'view-form/:anketId',
						name: 'viewForm',
						meta: { id: null, title: 'Просмотр анкеты' },
						component: () => import('../views/Minzdrav/FormConstructor/ui/AddForm.vue')
					}
				]
			},
			{
				path: 'administration-user',
				name: 'AdministrationUser',
				meta: { id: null, title: 'Пользователи Системы' },
				component: () => import('../views/Minzdrav/Administration/index.vue')
			},
			{
				path: 'administration-roles',
				name: 'AdministrationRoles',
				meta: { id: null, title: 'Роли Системы' },
				component: () => import('../views/Minzdrav/AdministrationRoles/index.vue')
			},
			{
				path: 'user-activity',
				name: 'UserActivity',
				meta: { id: null, title: 'Активность пользователей' },
				component: () => import('../views/Minzdrav/UserActivity/index.vue')
			},
			{
				path: 'patient-reviews',
				name: 'PatientReviewsMinzdrav',
				meta: { id: '213c7ff8-6b63-449d-9e4e-adc3fc9e5fe8', title: 'Отзывы пациентов' },
				component: () => import('../views/Minzdrav/PatientReviews/index.vue')
			}
		]
	},
	{
		path: '/ogv',
		name: 'OGV',
		component: () => import('../views/OGV/index.vue'),
		children: [
			{
				path: '/ogv/administration',
				name: 'Administration',
				meta: { id: null, title: 'Список пользователей' },
				component: () => import('../views/OGV/Administration/index.vue')
			},
			{
				path: '/ogv/general',
				name: 'GeneralOGV',
				meta: { id: null, title: 'Главная' },
				component: () => import('../views/OGV/General/index.vue')
			},
			{
				path: '/ogv/archive',
				name: 'Archive',
				meta: { id: '213c7ff8-6b63-449d-9e4e-adc3fc9e5fe5', title: 'Архив' },
				component: () => import('../views/OGV/Archive/index.vue')
			},
			{
				path: '/ogv/blocks-site',
				name: 'BlocksSite',
				meta: { id: null, title: 'Блоки для сайта' },
				component: () => import('../views/OGV/BlocksSite/index.vue')
			},
			{
				path: '/ogv/independent-evaluation-results',
				name: 'IndependentEvaluationResults',
				meta: { id: null, title: 'Результаты независимой оценки за 1-3 года' },
				component: () => import('../views/OGV/IndependentEvaluationResults/index.vue')
			},
			{
				path: '/ogv/medical-organizations',
				name: 'MedicalOrganizationsOGV',
				meta: { id: '213c7ff8-6b63-449d-9e4e-adc3fc9e5fe7', title: 'Медицинские организации' },
				component: () => import('../views/OGV/MedicalOrganizations/index.vue')
			},
			{
				path: '/ogv/noc-current-year',
				name: 'NOCCurrentYear',
				meta: { id: null, title: 'НОК текущего года' },
				component: () => import('../views/OGV/NOCCurrentYear/index.vue'),
				children: [
					{
						path: 'current-year',
						name: 'currentYear',
						meta: { id: null, title: 'Перечень МО текущего года' },
						component: () => import('../views/OGV/NOCCurrentYear/ui/CurrentYear.vue')
					},
					{
						path: 'decisions-public-council',
						name: 'decisionsPublicCouncil',
						meta: { id: null, title: 'Решения Общественного совета' },
						component: () => import('../views/OGV/NOCCurrentYear/ui/DecisionsPublicCouncil.vue')
					},
					{
						path: 'survey-results',
						name: 'surveyResults',
						meta: { id: null, title: 'Результаты анкетирования' },
						component: () => import('../views/OGV/NOCCurrentYear/ui/SurveyResults.vue')
					},
					{
						path: 'noc-results-current-years',
						name: 'NOCResultsCurrentYear',
						meta: { id: '213c7ff8-6b63-449d-9e4e-adc3fc9e5fe3', title: 'Результаты НОК текущего года' },
						component: () => import('../views/OGV/NOCCurrentYear/ui/NOCResultsCurrentYear.vue')
					}
				]
			},
			{
				path: '/ogv/patient-reviews',
				name: 'PatientReviewsOGV',
				meta: { id: '213c7ff8-6b63-449d-9e4e-adc3fc9e5fe8', title: 'Отзывы пациентов' },
				component: () => import('../views/OGV/PatientReviews/index.vue')
			},
			{
				path: '/ogv/public-council-noc',
				name: 'PublicCouncilNOC',
				meta: { id: '213c7ff8-6b63-449d-9e4e-adc3fc9e5fe6', title: 'Общественный совет по НОК' },
				component: () => import('../views/OGV/PublicCouncilNOC/index.vue')
			},
			{
				path: '/ogv/public-council-noc/compound',
				name: 'Compound',
				meta: { id: null, title: 'Состав ОС по НОК' },
				component: () => import('../views/OGV/PublicCouncilNOC/index.vue')
			},
			{
				path: '/ogv/public-council-noc/protocols',
				name: 'Protocols',
				meta: { id: null, title: 'Состав ОС по НОК' },
				component: () => import('../views/OGV/PublicCouncilNOC/index.vue')
			}
		]
	},
	{
		path: '/questionnaire/:type/:id',
		name: 'Questionnaire',
		meta: { id: null, title: 'Анкета НОК' },
		component: () => import('../views/Questionnaire/index.vue')
	},
	{
		path: '/:level/GetBannerNok/:id/:type',
		name: 'GetBannerNok',
		meta: { title: 'Баннер НОК' },
		component: () => import('../views/GetBannerNok/index.vue')
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.afterEach((to, from) => {
	if (to.meta.id) {
		actions.globalListLinkToAnkets(axios, to.meta.id);
	} else {
		mutations.setGlobalAnkets([]);
	}
});

export default router;
