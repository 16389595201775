import Vue from 'vue';
import _ from 'lodash';

const state = Vue.observable({
	user: {},
	MobileMenu: false,
	authUser: false,
	regions: null,
	years: null,
	anketFull: [],
	anket: [],
	region: '',
	dependencyQuestions: [],
	infoAnket: {
		created: '',
		medicalOrgName: '',
		numberBlock: '',
		titleBlock: '',
		completedQuestions: 0,
		totalQuestions: 0,
		result: 0,
		medicalId: '',
		anketaId: '',
		userFullName: ''
	},
	questionBlock: [],
	viewInfoObject: {
		fio: '',
		block: {
			title: '',
			value: '',
			flagMO: false,
			flagAction: false
		},
		fields: [
			{
				Name: 'Назначенные МО'
			}
		],
		url: ''
	},
	globalListLinkToAnkets: []
});

function updateStateLS() {
	let localUser = JSON.parse(localStorage.getItem('user')) || {},
		authUser = JSON.parse(localStorage.getItem('AuthUser')) || false;

	Object.keys(state).forEach((key) => {
		if (key === 'user') {
			state[key] = localUser[key] ? localUser[key] : state[key];
		}
	});
	state.authUser = authUser;

	if (!authUser) localStorage.setItem('user', JSON.stringify(state.user));
	localStorage.setItem('AuthUser', JSON.stringify(state.authUser));
}

updateStateLS();

export const getters = {
	menu: () => state.MobileMenu,
	authUser: () => state.authUser,
	getYears: () => state.years,
	regionName: () => state.region,
	regionsArr: () => state.regions,
	dataAnket: () => state.anket,
	getAuthUser() {
		return localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : state.user;
	},
	getInfoAnket: () => state.infoAnket,
	getBlock: () => state.questionBlock,
	getViewInfoObject: () => state.viewInfoObject,
	getGlobalListLinkToAnkets: () => state.globalListLinkToAnkets
};

export const mutations = {
	saveGlobalUser(user) {
		// console.log('saveGlobalUser', user);

		// Сохраняем токен глобально
		if (user.access_token) localStorage.setItem('token', user.access_token);
		Vue.prototype.$http.defaults.headers.common.Authorization = `Bearer ${user.access_token}`;

		state.user = user;
		localStorage.setItem('AuthUser', true);
		localStorage.setItem('user', JSON.stringify(state.user));

		updateStateLS();
	},
	setRegions(arr) {
		state.regions = arr;
	},
	setRegion(region) {
		state.region = region;
	},
	openMenu() {
		state.MobileMenu = !state.MobileMenu;
		updateStateLS();
	},
	resetGlobalUser() {
		// Очищаем токен глобально
		localStorage.removeItem('token');
		state.user = {};
		localStorage.setItem('AuthUser', false);

		updateStateLS();
	},
	setInfoAnket(payload, order) {
		let questionBlock = payload.anketa.questionBlocks.filter((f) => f.order === +order);

		// Нумерация
		let indNotParent = 0;
		for (let i = 0; i < questionBlock[0].questions.length; i += 1) {
			let indParent = 0;

			if (questionBlock[0].questions[i].isParent) {
				indNotParent += 1;
				questionBlock[0].questions[i].numberTitle = indNotParent;
			}

			for (let j = 0; j < questionBlock[0].questions.length; j += 1) {
				if (questionBlock[0].questions[i].id === questionBlock[0].questions[j].parentId) {
					questionBlock[0].questions[i].numberTitle = indNotParent;
					questionBlock[0].questions[j].numberTitle = +`${questionBlock[0].questions[i].numberTitle}.${(indParent += 1)}`;
				}
			}
		}

		state.infoAnket = {
			created: payload.anketa.created,
			medicalOrgName: payload.medicalOrgName,
			numberBlock: questionBlock[0].title.split(' ')[0],
			titleBlock: questionBlock[0].title.slice(questionBlock[0].title.split(' ')[0].length, questionBlock[0].title.length),
			completedQuestions: questionBlock[0].completedQuestions,
			totalQuestions: questionBlock[0].totalQuestions,
			result: questionBlock[0].result,
			medicalId: payload.medicalId,
			anketaId: payload.anketaId,
			userFullName: payload.userFullName
		};

		state.questionBlock = questionBlock;
	},
	setAnket(data, dep) {
		state.infoAnket = {
			titleBlock: data.name,
			anketaId: data.anketaId
		};

		state.anketFull = data.questionBlocks;

		state.anketFull.forEach((block) => {
			block.questions.sort((a, b) => a.blockNumber - b.blockNumber);
		});

		if (dep) {
			state.dependencyQuestions = [];
			state.anketFull.forEach((block) => {
				block.questions.forEach((question) => {
					question.answers.forEach((answer) => {
						if (answer.dependencyQuestions.length) {
							answer.dependencyQuestions.forEach((dependet) => {
								state.dependencyQuestions.push({
									id: dependet.dependencyQuestionId,
									parentId: question.id
								});
							});
						}
					});
				});
			});
		}

		state.dependencyQuestions = _.uniq(state.dependencyQuestions);

		this.resetAnket();
	},
	resetAnket() {
		state.anket = state.anketFull.map((blocks) => {
			return _.filter(blocks.questions, function (o) {
				let delAnswer = true;
				state.dependencyQuestions.forEach((item) => {
					if (item.id === o.id) {
						delAnswer = false;
					}
				});
				return delAnswer;
			});
		});

		state.anket.forEach((item) => {
			item.map((answer) => {
				answer.value = null;
				return answer;
			});
		});
	},
	mutationDependencyQuestions(currentId, idArr) {
		let returnId = [];

		returnId = _.filter(state.dependencyQuestions, function (question) {
			let delAnswer = true;

			if (currentId === question.id) {
				delAnswer = false;
			} else if (currentId === question.parentId) {
				if (idArr.find((x) => x === question.id) !== undefined) {
					delAnswer = false;
				}
			} else {
				for (let i = 0; i < state.anket.length; i += 1) {
					if (state.anket[i].find((x) => x.id === question.id) !== undefined) {
						delAnswer = false;
						break;
					}
				}
			}

			return delAnswer;
		});

		_.uniq(returnId);

		state.dependencyQuestions.forEach((x) => {
			if (returnId.find((y) => y.id === x.id) === undefined && returnId.find((y) => y.id === x.parentId) !== undefined) {
				returnId.push(x);
			}
		});

		state.anket = state.anketFull.map((block) => {
			return _.filter(block.questions, function (question) {
				let delAnswer = true;
				returnId.forEach((item) => {
					if (item.id === question.id) {
						delAnswer = false;
					}
				});

				if (!delAnswer) {
					question.value = null;
				}

				return delAnswer;
			});
		});
	},
	setViewInfoObject(payload) {
		state.viewInfoObject = payload;
	},
	setGlobalAnkets(array) {
		state.globalListLinkToAnkets = array;
	},
	yearMutations(years) {
		state.years = years;
	}
};

export const actions = {
	getAnketaWithBlocks(anketaResultId, order) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`Anketa/GetAnketaResultWithBlocks?anketaResultId=${anketaResultId}`)
				.then((res) => {
					mutations.setInfoAnket(res.data, order);
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getAnketa(anketaId, dependet) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`Anketa/GetAnketaWithBlocks?anketaId=${anketaId}`)
				.then((res) => {
					mutations.setAnket(res.data, dependet);
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getSystemYearData() {
		return new Promise((resolve, reject) => {
			this.$http
				.get('Region/GetSystemYearData')
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getRegionYearData(region) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`Region/GetRegionYearData?regionId=${region}`)
				.then((res) => {
					resolve(res);
					mutations.yearMutations(res.data);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getDecisionBlockStatus(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`/OS/GetDecisionBlockStatus?year=${parametrs.year}&type=${parametrs.type}&regionId=${parametrs.regionId}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getRegions() {
		return new Promise((resolve, reject) => {
			this.$http
				.get('MedicalOrgs/Regions')
				.then((res) => {
					mutations.setRegions(res.data);
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getRegion(id) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`Region/Get?id=${id}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getCurrentRegion() {
		return new Promise((resolve, reject) => {
			if (this.userIsInRoles(['Support', 'MZ_Employee', 'MZ_Observer'])) {
				this.getRegion(this.$route.query.regionId)
					.then((result) => {
						resolve(result.data);
					})
					.catch((error) => {
						reject(error);
					});
			} else if (this.getAuthUser.regionName) {
				resolve({
					id: this.getAuthUser.regionId,
					name: this.getAuthUser.regionName
				});
			}
		});
	},
	getMObyId(id) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`https://apianketanok.minzdrav.gov.ru/MO/GetMOById?moId=${id}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getMObyOldId(id) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`https://apianketanok.minzdrav.gov.ru/MO/GetMOByOldId?oldId=${id}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getOGVbyOldId(id) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`https://apianketanok.minzdrav.gov.ru/Region/GetRegionByOldId?oldId=${id}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getOGVbyId(id) {
		return new Promise((resolve, reject) => {
			this.$http
				.get(`https://apianketanok.minzdrav.gov.ru/Region/GetRegionById?regionId=${id}`)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	globalListLinkToAnkets(http, placeId) {
		return new Promise((resolve, reject) => {
			http.get(`Anketa/GetAnketsByPlace?placeId=${placeId}`)
				.then((res) => {
					mutations.setGlobalAnkets(res.data);
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};

export const methods = {
	userIsInRoles(roles) {
		if (this.getAuthUser.roles === undefined) {
			return false;
		}

		for (let i = 0; i < roles.length; i += 1) {
			if (this.getAuthUser.roles.includes(roles[i])) {
				return true;
			}
		}

		return false;
	},
	getRegionId() {
		return this.userIsInRoles(['Support', 'MZ_Employee', 'MZ_Observer']) ? this.$route.query.regionId : this.getAuthUser.regionId;
	}
};

export const compareAsc = function (a, b) {
	if (a > b) {
		return 1;
	}

	if (a < b) {
		return -1;
	}

	return 0;
};

export const compareDesc = function (a, b) {
	if (a > b) {
		return -1;
	}

	if (a < b) {
		return 1;
	}

	return 0;
};
